/*GLOBAL*/

.float-right {
  float: right
}

button[disabled=disabled],button:disabled{
    cursor:not-allowed;
    opacity:0.5;
}

html{
    height: 100%;
}

body{
    height:100%;
}

.mat-tooltip{
    font-size:12px !important;
}

code {
    color: #1f3e69;
    background-color: #f2f7f9;
}

/*SPACERS - default to bot-mar if possible*/

.bot-mar-lg{
    margin-bottom:60px !important;
}

.bot-mar-md{
    margin-bottom:40px !important;
}

.bot-mar-sm{
    margin-bottom:20px !important;
}

.bot-mar-xs{
    margin-bottom:10px !important;
}

.top-mar-lg{
    margin-top:60px !important;
}

.top-mar-md{
    margin-top:40px !important;
}

.top-mar-sm{
    margin-top:20px !important;
}

.top-mar-xs{
    margin-top:10px !important;
}

.modal-no-gutter-left{
    padding: 0 40px 0 0;
}

.modal-no-gutter-right{
    padding: 0 0 0 40px;
}

.l-title-toggle{
    position: absolute;
    left: 0;
    margin-top: -28px;
}

.r-title-toggle{
    position: absolute;
    left: 40px;
    margin-top: -28px;
}

/*FONTS*/
body{
    font-family: 'Open Sans', sans-serif;
    color:#525c65;
    font-size: 13px;
    background-color:#f7f7f7;
}

a{
    cursor: pointer;
}
h5{
    font-size: 16px;
}

textarea{
    border-radius: 5px;
    border: none;
    background-color: #f2f2f2;
}
.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.modal-label{
    font-weight: normal;
}

.hidden{
    display: none;
}

.list-group{
    margin-bottom: 0px;
}

.strike-through{
    text-decoration: line-through;
}

.disabled-price{
    text-decoration: line-through;
    color: #b9b2b2;
    margin-right: 4px;
}

/*USER AGENT*/
.mat-check{
    appearance: none;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: -1px;
    vertical-align: top;
    margin-right: 5px !important;
    margin-left: 20px !important;
    border: 2px solid rgba(0,0,0,0.54);
    border-radius: 2px;
    color: black;
    background-color: rgba(0,0,0,0) !important;
    outline: 0px !important;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.mat-check:disabled{
    border-color:rgba(0,0,0,0.25);
}

.mat-check:checked{
    background-color: #ff4081 !important;
    border-color: #ff4081;
}

.mat-check:checked:after{
    content: "\f00c";
    font-family: FontAwesome;
	font-size: 15px;
	color: #fff;
}

.mat-check-label{
    margin-top: 4px;
    font-weight: 600;
    position: relative;
    display: inline-block;
}

/*MODAL*/
.modal {
    background: rgba(0,0,0,0.6);
    overflow-y: scroll;
}

.modal-container{
    margin-right:15px;
    margin-left: 15px;
    width: 500px;
}

.modal-container-wide{
    margin:40px;
    min-height: 450px;
}

@media(min-width:1200px){
    .modal-container-wide{
        width:800px;
        max-width: 800px;
    }
}
@media(max-width:1199px) and (min-width:992px){
    .modal-container-wide{
        width: calc(80vw - 80px);
    }
}

@media(max-width:991px){
    .modal-container-wide{
        width: calc(100% - 80px);
        height: calc(100% - 320px);
    }
}

.modal-container.vm-overview.content{
    padding: 40px 40px 60px 40px;
    min-height: 400px;
    margin: 0px;
}

.modal-container.network-settings-content{
    margin: 0px;
    padding: 40px;
    width: 100%;
}

@media(min-width:992px){
    .modal-container.network-settings-content{
        width:600px;
    }
}

.modal-container-small{
    margin:40px !important;
    padding:0px !important;
}

.modal-content{
    border-radius: 3px;
    border: none !important;
}

.modal-header .single-image{
    width: 190px;
    position: absolute;
    right: 40px;
    bottom: 0px;
}

.modal-header .lb-image{
    height: 80px;
    position: absolute;
    right: 40px;
    top: 40px;
}

.modal-header{
    padding: 30px 15px 0px 15px;
    background-color: #1f3e69;
    color: white;
    border-radius: 3px 3px 0px 0px;
    position: relative;
    border-bottom: none !important;
}

.jumbo-header{
    min-height:160px;
    padding: 40px;
}

.modal-header-container{
    z-index: 10;
    position:absolute;
    bottom: 40px;
}

.modal-header div{
    display: inline-block;
}

.modal-header-close{
    position: absolute;
    right: 12px;
    font-size: 19px;
    top: 5px;
    cursor: pointer;
}

/*----*/

/*Have dialog widths set by content*/
:host /deep/ .mat-dialog-container{
    max-width: initial !important;
    width: initial !important;
}
@media(min-width:1199px){
    .large-dialog{
        width:880px;
        max-height: 770px;/*test*/
    }
    .small-dialog{
        width:500px;
    }
}
/*----*/

/*Text formatting*/

.modal-header small{
    color: white;
}
.modal-header h4{
    font-size: 36px;
    font-weight: 300;
    margin-top: 0px;
}
.modal-title{
    font-size: 30px;
    font-weight: 300;
}

.modal-content-title .deployment-type-selector{
    margin-right: 60px;
    cursor: pointer;
    transition: ease-in all 0.2s;
}

.deployment-type-selector.active, .deployment-type-selector:hover {
    color: #1E73BE;
}

.mini-modal-title{
    font-size: 14px;
    font-weight: 600;
}

.mini-modal-title > .edit{
    display: inline;
    position: absolute;
    right: 16px;
    cursor: pointer;
}

.mini-modal-notes{
    width: 100%;
    padding: 3px 5px;
    margin-top:-5px;
    font-size: 12px !important;
    max-width: 270px;
    min-width: 270px;
    height:57px;
    min-height: 57px !important;
    max-height: 85px;
}

.modal h3{
    font-weight: 300;
}

.modal-body{
    margin: 20px;
}

.modal-content-title{
    margin-top: 0px;
    /* margin-bottom:20px; */
    font-size:24px;
    font-weight:300;
}

.modal-dialogue{
    font-size:12px;
    margin:0;
    /* max-width:48%; */
}

.modal-centered-dialogue{
    font-size:14px;
    text-align: center;
    margin-top: 40px;
}

/*Buttons*/

.delete-btn{
    border-radius:3px !important;
    height:40px;
    width:180px;
    transition: ease-in 0.2s all;
    border: none !important;
    outline:0 !important;
    box-shadow: none !important;
}

.modal-btn{
    border-radius:3px !important;
    height:40px;
    margin-right:20px;
    width:180px;
    transition: ease-in 0.2s all !important;
    border: none !important;
    outline:0 !important;
    box-shadow: none !important;
}

.modal-btn:last-of-type{
    margin-right: 0px;
}

.modal-btn:hover, .modal-btn:focus{
    background-color: #1e66b7 !important;
    color:white;
}

.modal-btn:active{
    background-color: #183c6c !important;
}

.btn-danger:hover, .delete-btn:hover{
    background-color: #CE2F2C !important;
    color:white;
}

.btn-danger:focus, .delete-btn:focus{
    background-color: #d43f3a !important;
    color:white;
}

.modal-btn.small{
    height: 20px;
    padding: 1px;
    width: 80px;
    font-size:12px;
}

.modal-btn.medium{
    height: 30px;
}

.btn{
    border-radius: 5px;
}
.btn-primary{
    background-color: #1f3e69    ;
}
.btn.white-border{
    border:1px solid white;
}

.button-spinner > .mat-progress-spinner{
    height: 20px !important;
    width: 20px !important;
    display: inline-block !important;
    position: absolute;
    right: 10px;
    top: 10px;
}

.button-spinner > .mat-progress-spinner path, .button-spinner > .mat-spinner path {
    stroke: #fff !important;
}

/*MODAL FOOTER*/
.modal-footer{
    padding:0px 40px 40px 40px;
    border:none;
    position: relative;
}

.modal-footer > button{
    outline: none;
    font-size: 18px;
    min-width: 200px;
    min-height:40px;
    border:none !important;
    border-radius:3px !important;
    margin-left: 40px !important;
}

.action-btn{
    background-color: #1f3e69;
    border-radius:3px !important;
    height:40px;
    margin-right:20px;
    width:180px;
    transition: ease-in 0.2s all;
    border: none !important;
    outline:0 !important;
    box-shadow: none !important;
    color: white;
}

.action-btn:hover, .action-btn:focus{
    background-color: #1e66b7;
    color: white;
}

.action-btn:active{
    background-color: #183c6c;
}
/*----*/

/*FORM ELEMENTS*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}

input{
    background-color: #F2F2F2;
    border: 5px;
    padding: 8px;
    width: 100%;
}

select{
    background-color: #F2F2F2;
    border: 5px;
    padding: 8px;
    width: 100%;
}

.dist-select{
    background-color: white;
    border: none;
    font-size: 11px;
    padding: 0px;
    text-align: center;
}

.dist-select.selected{
    background-color: #e6e6e6;
}
input[disabled]{
    color:grey !important;
}

.modal-input{
    box-sizing: border-box;
    border-radius: 3px;
    min-height:40px;
    outline:none;
    border:1px solid #ddd;
    font-size:14px;
}

.notes-input{
    min-height: 100px;
    max-height: 166px;
    max-width: 360px;
    min-width: 360px;
    padding: 8px;
}
.notes-input.tags{
    min-height: 170px;
    max-height: 170px;
}

.login-label{
    width: 100%;
    font-weight: 600;
    text-align: left;
}

/*NOTIFICATIONS*/
.top-notification{
    min-height: 30px;
    width: 100%;
    background-color: #1E66B7;
    margin-top: 81px;
    left: 0;
    top: 50px;
    position: fixed;
    color: white;
    padding: 30px;
    font-size: 23px;
    font-weight: 300;
}

/*TITLE*/
.page-title{
    margin-top: 60px;
    margin-bottom: 60px;
}

h1 small{
    color: #8EB8DE;
    font-size: 16px;
    font-weight: 300;
}

.environment-breakdown .icon{
    width:20px;
}

/*CART - common*/
.cart-hourly{
    font-size: 60%;
}

/*VIEW ENVIRONMENT*/

/*Common inline text edit styling*/
.inline-edit{
    background-color: transparent;
    width: 300px;
    padding: 5px;
    margin: -5px;
    height:28px;
    transition: all ease-in 0.2s;
}
.inline-edit:hover{
    color:#1e66b7;
    cursor: pointer;
}
.inline-edit-text{
    cursor:pointer;
    width: 300px;
    display: inline-block;
    transition: all ease-in 0.2s;
}
.inline-edit-text:hover{
    color:#1e66b7;
    cursor: pointer;
}

/*Common Tier styling*/
.tier{
    position: relative;
    padding:20px 80px 20px 80px;
    min-height: 260px;
    border-bottom: 1px dashed #dddddd;
    background-color: #f7f7f7;
}

.tier > div{
    position: relative;
}

.tier h4{
    margin-bottom:12px;
    font-weight: 300;
    display:inline-block;
    min-width: 217px;
}

.tier-description{
    color: #808080;
    font-size: 12px;
}

.tier-right{
    position: absolute;
    display: inline-block;
    right: 0px;
    top: 0px;
    height: 40px;
    min-width: 200px;
    text-align: right;
}

.tier-control{
    height: 20px;
    right:0px;
    display: inline-block;
}
.tier-control > button{
    margin-left: 8px;
    max-height: 20px;
    border:none;
    padding-top: 2px;
    outline:none !important;
    transition: all ease-in 0.2s;
    width:40px;
    height:20px;
    border-radius: 3px;
    box-shadow: none !important;
}
.tier-control > button:hover{
    background-color: #cccccc;
}
.tier-control > button:disabled{
    opacity:0.5;
    cursor: not-allowed;
}
.tier-control > button > img{
    vertical-align: baseline;
    height:12px;
}
@media(max-width:991px){
    .tier-control > button{
        width: 30px;
    }
}

/*Common Flex Pricing (PAYG) Display*/
.flex-opt-wrap > small{
    font-size: 12px;
    color: #ffffff;
    opacity: 0.5;
    padding: 2px 0px 1px 8px;
    display: block;
    position: relative;
    text-align: right;
    transition: ease-in all 0.2s;
}
.flex-opt-wrap > small:hover{
    opacity: 0.75;
}
.flex-opt-load{
    position: relative;
    margin-left: 35px;
}

/*Environment Sidebar positioning requires root styling*/
.no-side-row{}
.yes-side-row > .tier{
    padding-right:360px;
}

/*Common VM creation modal styling*/
.operating-system{
    border-radius: 3px;
    padding: 10px;
    width: 80px;
    margin-top: 10px;
    margin-right: 20px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
    transition: ease-in all 0.2s;
}
.operating-system:last-child{
    margin-right:0px;
}
.operating-system > img{
    margin: 0;
}
.operating-system p{
    text-align: center;
    margin: 5px -10px;
    font-weight: 600;
    font-size: 12px;
    width: 80px;
}
.operating-system > p:nth-child(3){
    font-weight: 700;
    font-size: 11px;
}
.operating-system select{
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    padding: 0px;
    outline: none;
    background-color: rgba(0,0,0,0);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    width: auto;
}
.operating-system:hover{
    background-color: #E6E6E6;
}
.operating-system.selected{
    background-color: #E6E6E6;
}

.modal-breadcrum{
    display: inline;
    margin-right: 16px;
    color: #ffffff;
    opacity: 0.5;
}
.modal-breadcrum.active{
   opacity: 1;
}
.breadcrumb-icon{
    margin-bottom: 3px;
    width: 12px;
    margin-right: 8px;
}

.configurations .name{
    width:120px;
    display: inline-block;
    position: absolute;
    line-height: 1;
}
.vcpu-type-wrap{
    position: absolute;
    left: 350px;
    margin-top: -48px;
}
.stor-type-wrap{
    position: absolute;
    left: 270px;
    margin-top: -144px;
}
.rad-btn-test{
    display: block !important;
    margin-bottom: 2px;
}
.vcpu-radio-group{
    margin-left:8px;
    position: relative;
    left: 42px;
    top: -21px;
}
.stor-radio-group{
    margin-left:8px;
    position: relative;
    left: 8px;
    top: -15px;
}
input.config, select.config{
    width: 60px;
    display: inline-block;
    background: rgba(0,0,0,0);
    color: #1f3e69;
    font-weight: 600;
    /* text-align: right; */
    position: relative;
    outline: none;
    padding: 2px 4px;
    border-radius: 3px;
    transition: ease-in all 0.2s;
    background-color: #ececec;
    border: 1px solid #ddd;
}
input.config:hover, input.config:focus, select.config:hover, select.config:focus{
    background-color: #dddddd;
}
input.config:disabled, select.config:disabled{
    color: #1f3e69 !important;
}
.config-input-wrap{
    position: relative;
    display: inline-block;
    left: -70px;
    max-width:200px;
    top: -16px;
}
select.config{
    width: 140px;
}
/*----*/

/*Generic card-appearance container*/
.generic-container{
    position:relative;
    display:inline-block;
    background-color:white;
    border-radius:3px;
    box-shadow:0px 0px 10px 0px rgba(0, 0, 0, .25);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/*Generic Virtual Machine container*/



/*Common Placeholder VM design*/
.virtual-machine-wrap-placeholder{
    height:180px;
    display:inline-block;
}
.virtual-machine{
    width:200px;
    height:94px;
    margin: 20px 40px 10px 0px;
    z-index: 9;
}
.virtual-machine-wrap-placeholder.shortcut{
    cursor: pointer;
}
.vm-prop-placeholder{
    width: 75px;
    height: 8px;
    background-color: #eae9e9;
    margin: 3px 4px 12px 4px;
    border-radius: 3px;
}
.vm-name-placeholder{
    position:absolute;
    bottom:12px;
    left:10px;
    height: 8px;
    width: 100px;
    background-color: #eae9e9;
    border-radius: 3px;
}
.vm-deet-placeholder{
    margin-bottom: 14px;
    margin-left: 10px;
    height: 8px;
    width: 140px;
    background-color: #eae9e9;
    border-radius: 3px;
}
.vm-deet-placeholder:nth-child(odd){
    width:100px;
}
.generic-container.placeholder{
    box-shadow:0px 0px 1px 0px rgba(0, 0, 0, .25);
}
.module-os-wrap > img{
    height: 54px;
    width: 54px;
}
.action-placeholder{
    width: 11px;
    height: 11px;
    margin-bottom: 10px;
    border-radius: 3px;
    background-color: #eae9e9;
}
.module-os-wrap-placeholder{
    position:absolute;
    width:54px;
    height:54px;
    padding:0;
    background-color:#eae9e9;
    border-radius:3px;
    top: 10px;
    left: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.vm-actions-placeholder{
    width: 28px;
    border-left: 1px solid #ffffff;
    height: 66px;
    position: absolute;
    right: 2px;
    top: 3px;
    padding-left: 8px;
    padding-top: 6px;
    z-index: 20;
}
.action-placeholder{
    width: 11px;
    height: 11px;
    margin-bottom: 10px;
    border-radius: 3px;
    background-color: #eae9e9;
}
.virtual-machine-wrap-placeholder{
    height:180px;
    display:inline-block;
}
.virtual-machine-wrap-placeholder.shortcut{
    cursor: pointer;
}
.generic-container.placeholder > .vm-prop-container{
    position:absolute;
    top:10px;
    left:72px;
    height:54px;
}
.vm-prop-placeholder{
    width: 75px;
    height: 8px;
    background-color: #eae9e9;
    margin: 3px 4px 12px 4px;
    border-radius: 3px;
}
.vm-name-placeholder{
    position:absolute;
    bottom:12px;
    left:10px;
    height: 8px;
    width: 100px;
    background-color: #eae9e9;
    border-radius: 3px;
}
.vm-deet-placeholder{
    margin-bottom: 14px;
    margin-left: 10px;
    height: 8px;
    width: 140px;
    background-color: #eae9e9;
    border-radius: 3px;
}
.vm-deet-placeholder:nth-child(odd){
    width:100px;
}
/*----*/

/*MATERIAL OVERRIDES*/
.mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label, .mat-accent .mat-slider-track-fill {
    background-color: #1f3e69    ;
}
.mat-dialog-container {
    padding: 0 !important;
    border-radius: 3px !important;
}

/*Common dialogue styling*/
.confirm-dialog{
    padding: 0 !important;
}
.dialog-content{
    padding:20px;
}
/*Needs evaluation*/
/* .configurations img{
    width: 14px;
    display: inline-block;
}
.configurations label{
    font-weight: 100;
    font-size: 10px;
    padding-right: 28px;
} */

@media screen and (max-width:991px), screen and (max-height:600px){
    .mat-dia-wrap{
        max-width: 100vw !important;
        height: 100vh !important;
        position: relative;
    }
    /*Enable fullscreen on large modals on md viewport*/
    /*Will need to set to initial for certian cases*/
    .mat-dialog-container{
        max-width:100vw !important;
        width:100vw !important;
    }
}

/*Common price breakdown styling*/
.vm-details{
    padding-top:5px;
    font-size: 10px;
}
.vm-details img{
    width:10px;
    font-size: 8px;
    margin-right: -4px;
}
.vm-price{
    color: #1f3e69;
    font-size: 10px;
    font-weight: 600;
}
.vm-price.standalone{
    position: absolute;
    right:20px;
    font-size: 13px;
}
.price-breakdown{
    position: absolute;
    right:10%;
}
.vm-breakdown-spacer{
    border-color: #f7f7f7;
}
.vm-sum{
    line-height: 2;
    margin-bottom: 4px;
}
.vm-sum-price{
    color: #1f3e69;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    left: 89px;
}
@media(min-width:992px){
    .vm-sum-price{
        left: 75px;
    }
}
.vm-detail{
    display: inline-block;
}
.icon{
    width:20px;
    display: inline-block;
}

/*Common network colour styling*/
.circle-color{
    border-radius: 50px;
    width: 8px;
    height: 8px;
    background-color: grey;
    display: inline-block;
    margin-right: 10px;
}
.circle-color:last-of-type{
    margin-right: 0px;
}

.network-create-btn{
    margin-right: 0px;
    position: absolute;
    right: 15px;
}


.discount{
    color: red;
}
.discount small{
    color: red;
}

/*Shared network package styling*/
.bandwidth-container{
    width: 100px;
    background-color: #F2F2F2;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    margin: 0px 20px 0px 0px;
    padding: 10px 0px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}
.bandwidth-container:last-child{
    margin-right: 0px;
}
.bandwidth-container p{
    margin-bottom: 0px;
}
.bandwidth-container .speed{
    font-size: 16px;
}
.bandwidth-container .bandwidth{
    font-size: 13px;
    font-weight: 600;
}
.bandwidth-container .cost{
    font-size: 13px;
    color: #818181;
}
.bandwidth-container.active{
    background-color:#1f3e69;
    border:none;
}
.bandwidth-container.active > .speed, .bandwidth-container.active > .bandwidth{
    color:#ffffff;
}
.bandwidth-container.active > .cost{
    color: #ffffff;
    opacity:0.75;
}

/*Shared locations styling*/
.location-container{
    width: 100px;
    height:100px;
    background-color: #F2F2F2;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    margin: 0px 20px 0px 0px;
    padding: 10px 0px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    position: relative;
}
.location-container:last-of-type{
margin: 0;
}
.location-container.active {
    background-color: #1f3e69;
    border:none;
}
.location-container.active > .city{
    color: #ffffff;
}
.location-container .city{
    margin: 4px 0px;
    font-weight: 600;
    font-size: 13px;
}
.location-container .flag{
    width: 70px;
    margin:auto;
    text-align: center;
}
.location-container .data-center{
    font-weight: 600;
    font-size: 12px;
    padding:2px;
    margin-bottom: 0px;
}
.location-container > .flag{
    box-shadow:0 0px 15px 3px rgba(0,0,0,0.1);
}
.location-container .data-center.active{
    color: white;
}
.location-container.coming-soon{
    background-color: white;
    cursor: not-allowed;
}
.location-container.coming-soon > .flag{
    opacity: 0.5;
}

/*Shared title styling - needs to move up*/
.modal-content-title > .mat-slide-toggle{
    position: absolute;
    right: 10px;
    top: 10px;
}
.content-title{
    color:rgba(0, 0, 0, 0.87);
    display:inline-block;
    font-size:24px;
    font-weight:300;
    margin-top:0px;
}
.content-subheader{
    font-size:14px;
    font-weight:600;
}

.checkbox-select{
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7;
    width: 90px;
    height:40px;
    background: #dddddd;
    color: #555555;
    border-radius: 3px;
    padding: 8px 10px;
    text-align: center;
    margin: 0px 0px 20px 0px;
    position: relative;
}
.checkbox-select:last-child{
    margin-bottom: 0px;
}
.checkbox-select.selected{
    background: #1f3e69;
    color: white;
}
.checkbox-select-check{
    position: absolute;
    width:40px;
    top: 10px;
    left: 0px;
}
.checkbox-select.disabled{
    background: #dddddd;
    color: #555555;
}

/*Common modal header image placement?*/
.tier-header-img{
   height: 120px;
   width: 120px !important;
}

/*COMMON*/

.tooltip-icon-sm{
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-left: 10px;
    cursor: help;
}

/*Shared Firewall policy styling*/
.fw-policy-list{
    width:100%;
}

.fw-policy-list > tr{
    max-height: 20px !important;
}
.fw-policy-list > thead > tr > td{
    font-weight: bold;
    width: 20%

}

/*Shared PAYG toggle styling*/
.flex-opt-wrap{
    display: inline-block;
    position: absolute;
    right: 40px;
    z-index: 100;
}
.flex-opt-wrap > div, .flex-opt-wrap > span{
    text-align: right;
    font-weight: 300;
    font-size: 17px;
    color: #8EB8DE;
    cursor: pointer;
}
.flex-opt-wrap .selected{
    color:#ffffff;
    cursor: default;
}

/*Shared settings sidebar styling*/
.env-set-sidebar{
    border-right: 1px solid #000000;
    width: 200px;
    display: inline-block;
    position: relative;
    vertical-align: top;
}
ul{
    list-style: none;
    padding-left:0px;
}

.env-set-link-list {
    min-height: 450px;
    overflow-y: auto;
}

.env-set-link-list > li{
    margin-bottom: 20px;
}
.env-set-link{
    font-size: 14px;
    margin-bottom: 16px;
    color:black;
    outline: 0 !important;
    transition: ease-in all 0.2s;
}
.env-set-link:hover{
    color: #1f3e69;
    text-decoration: none;
}
.env-set-link.grey{
    cursor: not-allowed;
}
.env-set-link:active{
    color: #1f3e69;
    font-weight: bold;
}
.env-set-content{
    display: inline-block;
    /* width: calc(100% - 210px); */
    width: 100%;
    min-height: 500px;
    right: 0px;
    position: relative;
    /* padding-left: 40px; */
}

@media (max-width:767px) {
    .env-set-content, .page-header {
        padding: 0 40px;
    }
}

/* Sidebar Menu Styling */


body {
    padding: 0;
    margin: 0;
}
.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}
.mainInner {
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}
.mainInner div {
    display:table-cell;
    vertical-align: middle;
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 1.25px;
}
#sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 250px;
    transform: translateX(0);
    transition: transform 250ms ease-in-out;
    background: #eeeeeeee;
    padding: 120px 20px 40px 20px;
    z-index: 1;
    box-shadow: 0 0 20px 0px rgba(0,0,0,0.25);
    overflow-y: auto;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(-250px);
}

.sidebarMenuInner {
    margin:0;
    padding:0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li {
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
}
.sidebarMenuInner li a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    /* display: none; */
}

.openSidebarMenu{
    display:none;
}
.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;
    top: 25px;
    left: 25px;
    height: 22px;
    width: 25px;
}
.spinner {
    box-sizing: border-box;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #fff;
}
.horizontal {
    box-sizing: border-box;
    position: relative;
    margin-top: 3px;
    background-color:#fff;
}
.diagonal.part-1 {
    position: relative;
    box-sizing: border-box;
}
.diagonal.part-2 {
    box-sizing: border-box;
    position: relative;
    margin-top: 3px;
}

.menuSplash {
    top: 0;
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    height: 100px;
    width: 100%;
    background:linear-gradient(141deg, #1e73be, #1d5097);
    margin-left: -20px;
    padding: 10px 20px;
}

.menuSplash > h5 {
    color: white;
    font-weight: 700;
    opacity: 0.85;
    font-size: 14px;
    text-transform: uppercase;
}

@media(max-width:1700px) {
    #sidebarMenu{
        transform: translateX(-250px);
    }

    input[type="checkbox"]:checked ~ #sidebarMenu {
        transform: translateX(0);
    }
}

@media(max-width:576px) {
    #sidebarMenu{
        width: 100vw;
        transform: translateX(-100vw);
    }

    input[type="checkbox"]:checked ~ #sidebarMenu {
        transform: translateX(0);
    }
}

/*Shared settings user table styling*/
.user-table{
    width:100%;
    max-width: 628px;
}
.user-table > thead > tr > td{
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 10px;
}
.user-table > tbody > tr > td{
    padding-bottom: 10px;
}

.user-table > tbody > tr > td.error {
  color: crimson;
  font-weight: bold;
}


.add-user-wrap > input{
    display: inline-block;
    max-width: 200px;
    margin-right: 20px;
}

/* Prime NG override */
.ui-inputtext {
    background: #F2F2F2;
}

/*Root autocomplete UI overrides for Firewall section */
button.ui-widget.ui-button{
    background-color: #1f3e69;
    border-color: #1f3e69;
}
button.ui-widget.ui-button:hover, button.ui-widget.ui-button:focus{
    background-color: #1E73BE;
    border-color: #1E73BE;
}
.ui-button-shift > span > button{
    position: absolute !important;
    margin-right: 10px;
    right:0px;
}
.ui-button-shift > span > input{
    font-family: 'Open sans', sans-serif;
    min-height: 30px;
    padding: 4px;
    font-size: 13px;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    border: 1px solid #ddd;
    padding-right: 30px !important;
}
.ui-button-shift > span > ul{
    height: 30px;
    font-family: 'Open sans', sans-serif;
    padding-right: 30px;
}
.ui-button-shift > span > ul > li.ui-autocomplete-input-token{
    line-height: 1.7;
}
.form-item.source.val > .ui-button-shift > span > ul{
    min-width: 166px !important;
    max-width: 400px !important;
}
.form-item.dest.val > .ui-button-shift > span > ul{
    min-width: 166px !important;
    max-width: 400px !important;
}
.ui-state-highlight{
    border-color: #1f3e69 !important;
    background: #1f3e69 !important;
}
.ui-autocomplete-list-item{
    font-weight: 600 !important;
    font-size: 12px !important;
}
.ui-autocomplete-token.ui-state-highlight{
    font-weight: 600;
    padding: 2px 4px;
    margin-top: -1px;
    font-size: 12px !important;
    color:white;
}

/*Root override or the Clone section*/
.clone-select > .ui-widget{
    width: 38% !important;
    top: -2px;
}

/*Shared container for network info*/
.netw-tab-container{
    position: absolute;
    width: 300px;
    left: 40px;
    bottom: 25px;
}

/*Shared manage tags button element*/
button.manage-tags{
    position: absolute;
    border: 0px;
    background-color: initial;
    border-radius: 0px;
    font-weight: 600;
    font-size: 11px;
    color: #fff;
    background-image: url(/assets/images/icons/tag.svg);
    background-position-x: right;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 18px auto;
    width: 114px;
    outline: none;
    padding: 0px;
    line-height: 1.3;
    opacity: 0.5;
}

.tags-wrap{
    position: relative;
    height: 330px;
    overflow-y: auto;
}
.tags-row{
    position: relative;
    width: 100%;
    max-height: 40px;
}


.modal-btn.new-tag{
    height:40px;
    max-width: 58px;
    vertical-align: top;
}

.tags-table {
    width: 100%;
}
.tags-table > tbody > tr {
    height: 20px;
}
.tags-table > tbody > tr > td {
    padding: 0;
}

button.delete-tag{
    text-decoration: none;
    border: none;
    background: none;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    transition: ease-in all 0.2s;
}
button.delete-tag:hover{
    background-color:#eee;
}


.pol-mgmt-btn{
    margin-right: 8px;
    border: none;
    padding-top: 2px;
    outline: none !important;
    transition: all ease-in 0.2s;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 11px;
    vertical-align: top;
}

.pol-mgmt-btn.save{
    background-image: url(/assets/images/icons/save.svg);
}

.pol-mgmt-btn.delete{
    margin-right: 0px !important;
    background-image: url(/assets/images/icons/tier-trash.svg);
}

.pol-mgmt-btn.disabled{
    opacity: 0.5;
    cursor: initial;
}

.ui-button-shift>span>button{
    margin: 0px;
    height: 100%;
    background-color: #1f3e69;
    border-color: #1f3e69;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: white;
    background: #1f3e69;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 14px;
}

.p-autocomplete-item{
    font-size:12px;
}
